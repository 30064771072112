import { defineStore } from 'pinia';
import { PwaState, BeforeInstallPromptEvent } from 'src/interfaces/pwa';

export const usePwaStore = defineStore('pwa', {
  state: (): PwaState => ({
    deferredPrompt: null,
    isInstallable: false,
    isInstalled: false,
    showInstallBanner: false,
    showIOSGuideBanner: false,
  }),

  actions: {
    init() {
      // Vérifie si l'app est déjà en mode standalone
      if (
        window.matchMedia('(display-mode: standalone)').matches ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ('standalone' in window.navigator && (window.navigator as any).standalone === true)
      ) {
        this.isInstalled = true;
        return;
      }

      // Vérifier si déjà installé avec vérification de type
      if (navigator.getInstalledRelatedApps) {
        navigator.getInstalledRelatedApps().then((apps) => {
          this.isInstalled = apps.length > 0;
        });
      }

      // Log pour le debugging

      // Attacher l'événement avant tout
      const beforeInstallPromptHandler = (e: Event): void => {
        e.preventDefault();
        this.deferredPrompt = e as BeforeInstallPromptEvent;
        this.isInstallable = true;

        const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
        if (!isIOS) {
          this.showInstallBanner = true;
        }
      };

      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
      window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

      window.addEventListener('appinstalled', () => {
        this.isInstalled = true;
      });

      // Afficher le guide iOS si sur iPhone
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent) && !this.isInstalled) {
        this.showIOSGuideBanner = true;
      }
    },

    async installPwa() {
      if (!this.deferredPrompt) return;

      this.deferredPrompt.prompt();
      const choiceResult = await this.deferredPrompt.userChoice;

      if (choiceResult.outcome === 'accepted') {
        this.isInstalled = true;
      }

      this.deferredPrompt = null;
      this.showInstallBanner = false;
    },

    dismissInstallBanner() {
      this.showInstallBanner = false;
      this.showIOSGuideBanner = false;
    },
  },
});
