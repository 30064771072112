import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('pages/home/HomePage.vue'),
      },
      {
        path: 'demande',
        name: 'Demande',
        component: () => import('pages/demande/DemandePage.vue'),
        meta: { requiresActive: true },
      },
      {
        path: 'commissions',
        name: 'Commissions',
        component: () => import('pages/commissions/CommissionsPage.vue'),
        meta: { requiresActive: true },
      },
      {
        path: 'demandes',
        name: 'Demandes',
        component: () => import('pages/demandes/DemandesPage.vue'),
        meta: { requiresActive: true },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('pages/profile/ProfilePage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        component: () => import('pages/auth/LoginPage.vue'),
        name: 'Login',
      },
      {
        name: 'AskResetPassword',
        path: 'ask-reset-password',
        component: () => import('pages/auth/AskResetPassword.vue'),
      },
      {
        name: 'ResetPassword',
        path: 'reset-password',
        component: () => import('pages/auth/ResetPassword.vue'),
      },
      {
        name: 'RegisterPage',
        path: 'register',
        component: () => import('pages/auth/RegisterPage.vue'),
      },
    ],
  },
  {
    path: '/account',
    component: () => import('layouts/DeleteAccountLayout.vue'),
    children: [
      {
        name: 'DeleteAccount',
        path: 'delete',
        component: () => import('pages/profile/DeleteAccountPage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
