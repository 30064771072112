<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { usePwaStore } from 'src/stores/pwa-store';

export default defineComponent({
  name: 'App',
  mounted() {
    // Initialiser le store PWA après le chargement complet de l'app
    const pwaStore = usePwaStore();
    pwaStore.init();
  },
});
</script>
